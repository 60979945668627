<template>
  <section>
    <div class="ll-topbox">
    <!-- breadcrumb -->
    <div class="breadcrumb-top">
      <b-breadcrumb class="">
        <b-breadcrumb-item :to="{ name: 'dashboard' }" >
          <feather-icon
            icon="HomeIcon"
            size="16"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          {{ $t('Delivery Order') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item :to="{ name: 'pickup-request' }" >
          {{ $t('Pickup Request') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active >
          {{ $t('Create') }}{{ $t('Pickup Request') }}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>
    <!-- back button -->
    <b-button
      variant="flat-secondary"
      class="ll-cancel"
      :to="{ name: 'pickup-request' }"
    >
      <b-img
        :src="require('@/assets/images/imgs/return.png')"
        height="auto"
        class="mr-1"
      />
    </b-button>
    </div>

    <!-- first floor -->
    <validation-observer ref="rules">
    <b-row>
      <b-col lg="9" class="ll-padding">
        <b-card class="ll-card" :style="style1" >
          <b-card-header>
            <b-card-title>{{ $t('Pickup Location Information') }}</b-card-title>
            <div class="ll-required">* {{ $t('Required Fields') }}</div>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col lg="4">
                {{ $t('Used Pickup Location') }}
                <div class="ll-radioBox">
                  <b-form-radio
                    v-model="selecte"
                    name="some-radios"
                    value="oldLocation"
                    class="ll-radio"
                  >
                  </b-form-radio>
                  <v-select
                    class="ll-select"
                    :options="addressList"
                    label="address"
                    @input="selAddress"
                    :disabled="isOld"
                    v-model="address1"
                  ></v-select>
                </div>
              </b-col>
              <b-col lg="4" v-if="isNew">
                <br>
                <div class="ll-radioBox ll-radioBox1">
                  <b-form-radio
                    v-model="selecte"
                    name="some-radios"
                    value="newLocation"
                    class="ll-radio"
                  >
                  </b-form-radio>
                  <!-- <b-form-input /> -->
                  <span class="ll-select">{{ $t('New Pickup Location') }}</span>
                </div>
              </b-col>
            </b-row>
            <p style="margin-bottom:3rem"></p>
            <b-row>
              <b-col lg="4">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Contact person"
                    :rules="{required:true,max:20,regex:/^((?!\\).)*$/s}"
                  >
                    <label class="ll-boldText">{{ $t('Pickup Location Shipper Name') }}</label> {{ $t("(No \"\\\", Maximum Length: 20)") }}
                    <b-form-input v-model="info.person"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Contact phone"
                    :rules="{required:true,min:8,max:20,regex:/^((?!\\).)*$/s}"
                  >
                    <label class="ll-boldText">{{ $t('Pickup Location Shipper Contact') }}</label> {{ $t("(No \"\\\", Maximum Length: 20)") }}
                    <b-form-input v-model="info.phone" type="number" @keydown="formatNumber($event)"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
<!--              <b-col lg="4">-->
<!--                <b-form-group-->
<!--                  :label="$t('Email')"-->
<!--                  label-for="area"-->
<!--                >-->
<!--                  <validation-provider-->
<!--                    #default="{ errors }"-->
<!--                    name="Email"-->
<!--                    rules="email"-->
<!--                  >-->
<!--                    <b-form-input v-model="info.email"/>-->
<!--                    <small class="text-danger">{{ errors[0] }}</small>-->
<!--                  </validation-provider>-->
<!--                </b-form-group>-->
<!--              </b-col>-->
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group
                  :label="$t('')"
                  label-for="address"
                >
                <validation-provider
                    #default="{ errors }"
                    name="Address"
                    :rules="{required:true,max:225,regex:/^((?!\\).)*$/s}"
                  >
                    <label class="ll-boldText">{{ $t('Pickup Location Address') }}</label> {{ $t("(No \"\\\", Maximum Length: 225)") }}
                    <b-form-input v-model="info.address"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group
                  :label="$t('')"
                  label-for="district"
                >
                <validation-provider
                    #default="{ errors }"
                    name="District"
                    :rules="{max:50,regex:/^((?!\\).)*$/s}"
                  >
                    <label class="">{{ $t('Pickup Location District') }}</label> {{ $t("(No \"\\\", Maximum Length: 50)") }}
                    <b-form-input v-model="info.district"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group
                  :label="$t('')"
                  label-for="area"
                >
                <validation-provider
                    #default="{ errors }"
                    name="Area"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t('Pickup Location City') }}</label>
                    <v-select
                      :options="areaList"
                      label="codeDescription"
                      @input="selArea"
                      v-model="hkArea"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group label="" label-for="">
                  <validation-provider
                    #default="{ errors }"
                    name="Sender Name"
                    :rules="{max:50,regex:/^((?!\\).)*$/s}"
                    >
                    <label>{{ $t('Pickup Location Company Name')}} {{ $t("(No \"\\\", Maximum Length: 50)") }}</label>
                    <b-form-input v-model="info.senderName"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="" label-for="">
                <validation-provider
                    #default="{ errors }"
                    name="Schedule"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t('Pickup Schedule') }}</label>
                    <div style="display:flex">
                      <flat-pickr
                        id="formTime"
                        v-model="pickupSchedule.date"
                        class="form-control"
                        @on-open="onOpen"
                        :config="{ enableTime: false,dateFormat: 'Y-m-d'}"
                        style="width: 50%"
                      />
                      <v-select
                        :options="timeList"
                        @input="selTime"
                        v-model="pickupSchedule.time"
                        style="margin-left:5px;width:50%"
                      />
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="4" v-permission="[247]">
                <b-form-group
                  :label="$t('Account')"
                  label-for="customer"
                >
                  <v-select
                  v-model="customer"
                  :options="customerList"
                  label="info"
                  @input="selCustomer"
                  placeholder="Select Customer"
                  ></v-select>
                </b-form-group>
              </b-col>
            </b-row>
            <p></p>
            <p v-if="isAdd">
              <b-form-checkbox
                name="check-button"
                plain
                inline
                v-model="info.isAdd"
              >
                {{ $t('Add to the address book') }}
              </b-form-checkbox>
            </p>
            <p v-if="isDefault">
              <b-form-checkbox
                name="check-button"
                plain
                inline
                v-model="info.isDefault"
              >
                {{ $t('Make this location as default') }}
              </b-form-checkbox>
            </p>
          <!-- </b-card-body>
        </b-card>
        <b-card class="ll-card" :style="style1">
          <b-card-header>
            <b-card-title>Packages for collection</b-card-title>
          </b-card-header>
          <b-card-body> -->
            <hr style="margin: 2rem 1.5rem 1.5rem 1.5rem"/>
            <h4 style="padding: 1.5rem 0">{{ $t('Package Information') }}</h4>
            <b-row>
              <b-col lg="4" v-if="false">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Goods type"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t('Goods type') }}</label>
                    <v-select
                      :options="goodsList"
                      label="name"
                      placeholder="Please select"
                      @input="selGoods"
                      v-model="info.goodsType"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Total # of packages"
                    :rules="{required:true,integer:true, max_value:99}"
                  >
                    <label class="ll-boldText">{{ $t('Number Of Package') }}(Maximum: 99, Only Accept Number)</label>
                    <b-form-input v-model="info.totalPackages" type="number" @keydown="formatNumber($event)"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group
                  label=""
                  label-for=""
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Total weight"
                    :rules="{max:10,regex:/^((?!\\).)\d{0,5}(?:\.\d{0,2})?$/s}"
                  >
                    <label class="">{{ $t('Declared Gross Weight') }} (KG)</label> {{ $t("(Only accept Number, 2 decimal places, Maximum Length: 10)") }}
                    <b-form-input type="number" v-model="info.totalWeight" @keydown="formatNumber($event)"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group
                  label=""
                  label-for=""
                >
                  <validation-provider
                      #default="{ errors }"
                      name="Remark"
                      :rules="{max:50,regex:/^((?!\\).)*$/s}"
                  >
                    <label class="">{{ $t('Remark') }}</label> {{ $t("(No \"\\\", Maximum Length: 50)") }}

                    <b-form-input v-model="info.remark"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- <b-col lg="4">
                <b-form-group
                  :label="$t('Total Package')"
                  label-for="s"
                >
                  <b-form-input type="number" v-model="info.totalPackages"/>
                </b-form-group>
              </b-col> -->
              <b-col lg="4">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Payment method"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t('COD Service') }}</label>
                    <v-select
                      :options="paymentList"
                      label="codeDescription"
                      v-model="paymentType"
                      @input="selPaymentType"
                    >
                    <template #option="{ key, des }">
                      <span class="ml-50 align-middle"> {{ key }}({{ des}})</span>
                    </template>
                  </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="false">
              <b-col lg="4">
                <b-form-group
                  :label="$t('Total weight')"
                  label-for="goodsType"
                >
                  <b-form-input
                    placeholder="kg"
                    v-model="info.totalWeight"
                    type="number" @keydown="formatNumber($event)"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group
                  :label="$t('Dimension') +'(L x W x H)'"
                  label-for="pck"
                >
                  <b-row>
                    <b-col lg="4">
                      <b-form-input placeholder="cm" v-model="info.length" type="number" @keydown="formatNumber($event)"/>
                    </b-col>
                     <b-col lg="4">
                      <b-form-input placeholder="cm" v-model="info.width" type="number" @keydown="formatNumber($event)"/>
                    </b-col>
                     <b-col lg="4">
                      <b-form-input placeholder="cm" v-model="info.height" type="number" @keydown="formatNumber($event)"/>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- submit btn -->
            <div class="ll-subBtn">
              <b-button
                variant="info"
                :disabled="!pickupSchedule.time"
                @click.prevent="validationForm"
              >
                {{ $t('Submit') }}
              </b-button>
            </div>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col lg="3">
        <b-card class="ll-card1" :style="style2">
          <b-card-header>
            <b-card-title>{{ $t('Cut-off Time') }}</b-card-title>
          </b-card-header>
          <b-card-body>
            <div class="ll-cutBox">
            <p>{{ $t('From Monday to Friday') }}</p>
            <ul class="ll-ul">
              <li>
                {{ $t('Submit pickup request before 10:30') }} <br>
                {{ $t('ETA pickup time: Same day before 13:00') }}</li>
              <li>
                {{ $t('Submit pickup request before 15:30') }} <br>
                {{ $t('ETA pick up time: Same day before 18:00') }}
              </li>
              <li>
                {{ $t('Submit pickup request after 15:30') }}<br>
                {{ $t('ETA pick up time: Next working day before 13:00') }}
              </li>
            </ul>
            <p>{{ $t('From Saturday') }}</p>
            <ul class="ll-ul">
              <li>
                {{ $t('Submit pickup request before 10:30') }}<br>
                {{ $t('ETA pickup time: Same day before 13:00') }}
              </li>
              <li>
                {{ $t('Submit pickup request after 10:30') }}<br/>
                {{ $t('ETA pick up time: Next working day before 13:00') }}
              </li>
            </ul>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    </validation-observer>
  </section>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BFormTextarea,
  BRow, BCol, BCard, BFormGroup, BFormInput, BBadge, BTable,
  BButton, BSidebar, BInputGroupPrepend, BInputGroup,
  BCardHeader, BCardTitle, BCardBody, BTab, BTabs,
  BPagination, BFormSelect, BFormCheckbox, BFormRadio, BImg,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import { debounce } from "@/libs/fun.js"
import permission from '@core/directives/permission/index.js'
import {formatNumber} from "@core/utils/filter";
export default {
  directives: {
    permission,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BBadge,
    BButton,
    BTable,
    flatPickr,
    BSidebar,
    vSelect,
    BInputGroupPrepend,
    BInputGroup,
    BFormTextarea,
    BTab,
    BTabs,
    BPagination,
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormCheckbox,
    BFormRadio,
    ValidationProvider,
    ValidationObserver,
    BImg,
  },
  data() {
    return {
      required,
      email,
      selecte: 'oldLocation',
      isAdd: false,
      isDefault: true,
      goodsList: [
        { name: 'Parcel' },
        { name: 'Document' }
      ],
      areaList: [],
      addressList: [],
      isOld: false,
      isNew: true,
      windowHeight: document.documentElement.clientHeight,
      cardHei: document.documentElement.clientHeight *0.8 ,
      cardHei1: document.documentElement.clientHeight *0.8,
      info: {
        address: null,
        district: null,
        area: null,
        person: null,
        phone: null,
        isDefault: false,
        isAdd: false,
        // goodsType: null,
        // totalWeight: null,
        // length: null,
        // width: null,
        // height: null,
        remark: null,
        email: null,
        paymentType: 'PP',
        totalPackages: null,
        totalWeight: null,
        pickupSchedule: null,
      },
      paymentList: [
        { key: 'PP', des: 'Prepaid' },
      ],
      address1: null,
      paymentType: 'PP',
      hkArea: '',
      timeList: ['AM','PM','ANYTIME'],
      pickupSchedule:{
        date: null,
        time: 'AM'
      },
      customerList:[],
      customer:''
    }
  },
  computed: {
    style1() {
      return {
        '--height': this.cardHei + 'px'
      }
    },
    style2() {
      return {
        '--height1': this.cardHei1 + 'px'
      }
    }
  },
  watch: {
    selecte(val) {
      if (val === 'newLocation') {
        this.isDefault = false
        this.isOld = true
        this.info = {}
        this.address1 = null
        this.isAdd = true
        this.info.isAdd = false
      } else {
        this.isDefault = true
        this.isOld = false
        this.isAdd = false
      }
    },
    windowHeight(val) {
      // console.log(val)
      this.cardHei = val *0.68
      this.cardHei1 = val *0.68
    },
  },
  created() {},
  mounted() {
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight
        this.windowHeight = window.fullHeight
      })()
    },
    this.queryAddress()
    this.queryArea()
    this.queryPM()
    this.queryCustomer()
  },
  methods: {
    formatNumber,
    onOpen(selectedDates, dateStr, instance){
      let  time = (new Date).getTime() - 24 * 60 * 60 * 1000;
      instance.set('disable', [
            {
                from: "1970-01-01",
                to: new Date(time)// 获取的是前一天日期
            }
        ])
    },
    queryCustomer() {
      this.$http.get('/admin/settings/organization/findAllOrganization').then(res => {
         this.customerList = res.data.data.map(res=>{
          let arr = {id:res.id,orgCode:res.orgCode, info:res.orgCode+' - '+res.displayName+' - '+res.keAccountNumber,name:res.name}
          return arr
        })

      })
    },
    selCustomer(d) {
      this.info.customer = d.orgCode
      this.$http.get('/lmd/pickAddress/find?orgCode='+d.orgCode).then( res => {
        if (res.data.data) {
          this.info.address = res.data.data.pickUpAddress
          this.hkArea = res.data.data.pickUpArea
          this.info.district = res.data.data.pickUpDistrict
          this.areaList.find(item=>{
            if (this.hkArea == item.codeDescription) {
              this.info.area = item.codeName
            }else{
              this.info.area = ''
            }
          })
        }else{
          this.info.address = ''
          this.hkArea = ''
          this.info.district = ''
          this.info.area = ''
        }
        
      })
    },
    queryAddress() {
      this.$http.get('pickupRequest/getAddressBookList').then( res => {
        const info = res.data.data
        // console.log(info)
        if (info.length === 0) {
          this.isOld = true
          this.selecte = 'newLocation'
        } else {
          this.isOld = false
          this.selecte = 'oldLocation'
        }
        this.addressList = info
        info.reverse().find(i=>{
          if(i.isDefault) {
            // this.address1 = i.address
            this.info = info.reverse()[0]
            this.address1 = this.info.address
          }
        })
      })
    },
    selAddress(d) {
      this.info = d
      this.info.isAdd = false
    },
    queryArea() {
      this.$http.get('/masterData/findAllByDataType', { params: { data_type: 'hkArea'}}).then(res => {
        this.areaList = res.data.data
      })
    },
    selArea(d) {
      this.info.area = d ? d.codeName : null
      this.hkArea = d ? d.codeDescription : null
    },
    queryPM() {
      // this.$http.get('/masterData/findAllByDataType', { params: { data_type: 'pickupPaymentMethod'}}).then(res => {
      //   this.paymentList = res.data.data
      // })
    },
    selPaymentType(d) {
      this.info.paymentType = d ? d.key : null
      this.paymentType = d ? d.key : null
    },
    selGoods(d) {
      this.info.goodsType = d ? d.name : null
    },
    submit: debounce(function() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Submitting, please wait...",
          icon: 'InfoIcon',
          variant: 'info',
        },
      })
      this.$http.post('pickupRequest/create', this.info).then (res => {
        if (res.data.code === '200') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Request is submitted',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.queryAddress()
          this.$router.push({ name: 'pickup-request' })
        }
      })
    }, 1000),
    validationForm() {
      this.info.pickupSchedule = this.pickupSchedule
      this.$refs.rules.validate().then(success => {
        if (success) {
          this.submit()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please check the data',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          return false
        }
        return true
      })
    },
    selTime(d) {
      this.pickupSchedule.time = d ? d : null
    },
  },
}
</script>

<style scoped>
.ll-subBtn{
  text-align: right;
  height: 50px;
  position: absolute;
  bottom: 0;
  right: 10px;
}
.ll-cutBox{
  padding: 10px;
  /* background-color: #e2f5fd; */
}
.ll-cutBox p{
  font-weight: bold;
}
.ll-cutBox li {
  padding: 5px 0;
}
.ll-radioBox {
  margin-bottom: 1rem;
}
.ll-radioBox1{
  margin-top: 10px;
}
.ll-radioBox .ll-radio{
  display: inline-block;
  /* width: 10%; */
}
.ll-select {
  display: inline-block;
  width: 90%;
}
.ll-card{
  margin-bottom: 10px;
  height: var(--height);
}
.ll-card1{
  height: var(--height1);
}
.ll-padding{
  padding-right: 0;
}
/* .ll-boldText{
  font-weight: bold;
} */
.ll-ul{
  padding-left: 1.5rem;
}
</style>
<style>
[dir] .vs--disabled .vs__dropdown-toggle, [dir] .vs--disabled .vs__clear, 
[dir] .vs--disabled .vs__search, [dir] .vs--disabled .vs__selected, 
[dir] .vs--disabled .vs__open-indicator {
  background-color: unset !important;
}
</style>